








































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import moment from "moment-jalaali";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import { UserApi } from "@/networks/UserApi";
import Office from "@/views/components/inputs/office.vue";
import { EventApi } from "@/networks/EventApi";
import Enum from "@/config/enum";

@Component({
  components: {
    DatetimePicker,
    Office: Office
  }
})
export default class UserEvent extends Vue {
  private minDate = moment().add(2, "days").format("jYYYY/jMM/jDD");
  private types = Enum.eventTypesQuickWithPrice;
  private times: Array<Record<string, string>> = [];
  private form = {
    summery: "",
    type: "",
    office_id: "",
    date: "",
    time: ""
  };
  private hideOffice = false;
  private errors: Record<string, Array<string>> = {};
  private datePopup = false;

  mounted() {
    for (let i = 10; i <= 21; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
  }

  public async submit(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().userAdd(this.form);
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
      this.errors = {};
      this.form = {
        summery: "",
        type: "",
        office_id: "",
        date: "",
        time: ""
      };
      store.commit("showError", {
        message: res.data.message,
        color: "success",
        meta: {
          user: res.data.data?.user?.id,
          payment: res.data.data?.event?.id
        }
      });
      await this.$router.push({
        name: "events-view",
        params: { id: res.data.data?.event?.id }
      });
    } catch (error: any) {
      if (error.response && error.response?.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async checkAvailability(): Promise<void> {
    try {
      console.log(this.form);
      const res: AxiosResponse = await new UserApi().checkAvailability({
        office_id: `${this.form.office_id}`,
        date: this.form.date,
        type: this.form.type
      });
      const times: Record<string, string>[] = [];
      this.times.map((x) => {
        let insert = false;
        res.data.data.forEach((y: { time: string; cnt: string }) => {
          if (y.time.substr(0, 5) == x.value) {
            times.push({
              text: `${x.value}` + (y.cnt ? "" : "رزرو شده"),
              value: `${x.value}`,
              cnt: `${y.cnt}`
            });
            insert = true;
          }
        });
        if (!insert) {
          times.push(x);
        }
      });
      this.times = times;
    } catch (error: any) {
      console.log(error);
    }
  }

  @Watch("form.date")
  public onDateChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.checkAvailability();
    }
  }

  @Watch("form.office_id")
  public onOfficeChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.checkAvailability();
    }
  }

  @Watch("form.type")
  public onTypeChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      if (Enum.onlineTypes.includes(val)) {
        this.hideOffice = true;
        this.form.office_id = "";
        const times = [];
        for (let i = 10; i <= 21; i++) {
          times.push({
            text: `ساعت ${i}:00`,
            value: `${("00" + i).substr(-2)}:00`,
            cnt: "0"
          });
        }
        this.times = times;
      } else {
        this.hideOffice = false;
        const times = [];
        for (let i = 11; i <= 19; i++) {
          times.push({
            text: `ساعت ${i}:00`,
            value: `${("00" + i).substr(-2)}:00`,
            cnt: "0"
          });
        }
        this.times = times;
      }
    }
  }
}
